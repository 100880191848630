@media screen and (max-width: 768px) {
  .Services {
    padding: 25% 0;
    .cards {
      grid-template-columns: repeat(2, 1fr);
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 425px) {
  .Services {
    // padding-bottom: 5rem;
    .cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
