@media only screen and (max-width: 768px) {
  .Burger {
    display: block;
    div {
      transition: all 0.3s ease;
    }
    &.toggle .line1 {
      transform: rotate(-45deg) translate(-5px, 6px);
    }

    &.toggle .line2 {
      opacity: 0;
    }

    &.toggle .line3 {
      transform: rotate(45deg) translate(-5px, -6px);
    }
  }
}
