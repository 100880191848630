@import "../../variables";
.CoreValues {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 60vh;
  position: relative;
  background-color: $secondary-color;
  margin-top: -1px;

  .container {
    width: 90%;
    height: 100%;
    margin: 5% 0;
  }

  .title {
    margin: 0.5rem 0;
    border-bottom: 1px solid darken($color: $secondary-color, $amount: 10%);
    width: 90%;
    margin-bottom: 8px;
    text-align: center;
    padding: 0.5rem 0;
    padding-bottom: 1rem;
    font-weight: 800;
  }

  .cards {
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    font-weight: 600;

    .card {
      min-width: 33%;
      min-height: 20vh;
      position: relative;
      &:hover {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
        color: inherit;
      }
    }
  }

  .divider {
    @include divider-styling;
    height: 3vh;
  }
}

@import "./CoreValuesMobile";
