@import "../../variables";

.Services {
  position: relative;
  top: 1vh;
  .container {
    width: 90%;
    .header {
      border-bottom: 1px solid $secondary-color;
      width: 95%;
      margin-bottom: 8px;
      padding: 5px;
      text-align: center;
    }
  }

  .cards {
    // padding-bottom: 30%;
    // min-height: 200vh;
    margin: 1rem 0;
    padding-bottom: 15%;
    .card {
      height: 30vh;
    }
  }

  .divider {
    @include divider-styling;
  }
}
@import "./ServicesMobile";
