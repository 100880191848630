@import "../../variables";
.Home {
  @include section-styling;
  background-color: $primary-bg-color;
  min-height: 50vh;
  text-align: center;
  letter-spacing: 3px;
  line-height: 1.6;
  font-weight: 800;

  .container {
    width: 80%;
    position: absolute;
    top: 0vh;
    left: 0vw;
    right: 0vw;
  }

  .title {
    line-height: 1.6;
    border-bottom: 1px solid $secondary-color;
    margin-bottom: 8px;
    padding: 5px;
    font-weight: 800;
    animation: textFadeIn 1s;
    margin-bottom: 1rem;
    text-shadow: -4px 1px 4px rgba(14, 13, 13, 0.6);
    text-transform: uppercase;
  }

  .slogan {
    animation: textSlide 2s;
    font-weight: 200;
    font-style: italic;
    font-size: 1.2rem;
  }
}
@keyframes textFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes textSlide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@import "./HomeMobile.scss";
