// 4k Devices
@media screen and (min-width: 2560px) {
  .Home {
    .container {
      width: 100%;
    }
    .title {
      font-size: 5rem;
      letter-spacing: 5px;
    }

    .slogan {
      font-size: 2rem;
    }
  }
}

// Regular Laptops

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .Home {
    .title {
      font-size: 3rem;
    }
  }
}

// Tablets
@media screen and (max-width: 768px) {
  .Home {
    min-height: 30vh;

    .container {
      width: 80%;
    }
    .title {
      font-size: 2.5rem;
    }

    .slogan {
      font-size: 0.7rem;
    }
  }
}

// Large Phones
@media screen and (max-width: 426px) {
  .Home {
    .title {
      font-size: 1.5rem;
    }

    .slogan {
      font-size: 0.6;
    }
  }
}

@media screen and (max-width: 376px) {
  .Home {
    .title {
      font-size: 1.3rem;
    }

    .slogan {
      font-size: 0.5rem;
    }
  }
}
