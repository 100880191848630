@import "../../variables";
.AboutUs {
  padding: $top-and-bottom-section-padding;

  @include section-styling;

  .michael {
    max-width: 200px;
    border-radius: 50%;
    transform: translateY(-20px);
    position: relative;
    clip-path: circle(75%);
    padding: 0.3rem;
  }

  .title-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $secondary-color;
    width: 90%;
    margin-bottom: 8px;
    padding: 5px;
  }

  .subheading {
    margin: 1rem 0;
  }

  .description {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 10%;
    width: 85%;
    p {
      margin: 0.5rem;
      margin-bottom: 1.5rem;

      &:nth-child(2),
      &:last-child {
        align-self: center;
      }
    }
  }

  .divider {
    @include divider-styling;
  }
}

@import "./AboutUsMobile";
