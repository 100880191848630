@import "../../variables";

.Gallery {
  width: 100%;
  min-height: 90vh;
  position: relative;
  margin-top: 2rem;
  padding: $top-and-bottom-section-padding;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light-text-color;
  transition: background-image 3s;
  background-image: url("../../assets/ConstructionHome-mod.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  animation-name: slideBg;
  animation-direction: alternate-reverse;
  animation-duration: 30s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  .container {
    z-index: 1;
    position: absolute;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    text-align: center;
  }
}

@keyframes slideBg {
  25% {
    background-image: url("../../assets/ConstructionHome-mod.jpg");
  }

  50% {
    background-image: url("../../assets/house-mod.jpg");
  }

  75% {
    background-image: url("../../assets/interior-mod.jpg");
  }

  100% {
    background-image: url("../../assets/outside-mod.jpg");
  }
}

@import "./GalleryMobile";
