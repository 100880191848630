// $primary-color: #7c7c7c;

$secondary-color: #bebebe;

$primary-bg-color: #efefef;
$light-text-color: #eee;
$dark-text-color: #333;
$linear-gradient: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
$top-and-bottom-section-padding: 3rem 0;

@mixin section-styling {
  position: relative;
  padding: $top-and-bottom-section-padding;
}

@mixin divider-styling {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
