@import "../../variables";
.Burger {
  position: fixed;
  z-index: 1;
  top: 0px;
  margin-top: 1rem;
  right: 0;
  padding: 0px;
  cursor: pointer;
  display: none;
  div {
    background: darken($light-text-color, 50%);
    margin: 5px;
    width: 30px;
    height: 2px;
  }
}

@import "./BurgerMobile";
