@media screen and (min-width: 2560px) {
  .AboutUs {
    .michael {
      max-width: 400px;
    }
  }
}

@media screen and (min-width: 1440px) and (max-width: 2559px) {
  .AboutUs {
    .michael {
      max-width: 300px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .AboutUs {
    .michael {
      max-width: 200px;
    }
  }
}

@media screen and (max-width: 768px) {
  .AboutUs {
    .michael {
      max-width: 150px;
    }

    .title-div {
      width: 100%;
    }
  }
}

@media screen and (max-width: 320px) {
  .AboutUs {
    .michael {
      max-width: 150px;
    }

    .title-div {
      width: 100%;
    }

    .heading {
      font-size: 1.3rem;
    }
  }
}
