@import "../../variables";

@media screen and (min-width: 2560px) {
  header {
    .Navbar {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  header {
    .Navbar {
      font-size: 1.3rem;
      .nav-links {
        position: fixed;
        right: 0;
        height: 100vh;
        top: 0;
        flex-direction: column;
        justify-content: baseline;
        align-items: center;
        background-color: rgba(33, 33, 33, 0.7);
        width: 60%;
        transform: translateX(100%);
        transition: transform 0.5s ease-in;

        .nav-link {
          .nav-item {
            color: $light-text-color;
          }
        }

        &.nav-active {
          transform: translateX(0%);
        }

        li {
          opacity: 0;
        }
      }
    }
  }
}
