@import "../../variables";
header {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  .Navbar {
    width: 100%;
    position: absolute;
    top: 0vh;
    padding: 0.5rem 0;
    padding-bottom: 2rem;
    left: 0;
    max-height: 12vh;
    font-size: 1.2rem;
    margin: 0 auto;
    z-index: 3;
    background-color: #efefef;
  }

  .nav-links {
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .nav-item {
    transition: all 0.3s ease-in-out;
    color: darken($secondary-color, 25%);
    font-weight: 600;
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      margin: auto;
      height: 3px;
      width: 0px;
      background: transparent;
      transition: width 0.5s ease, background-color, 0.5s ease;
    }
    &:hover {
      color: darken($secondary-color, 40%);

      &::after {
        width: 100%;
        background-color: darken($color: $primary-bg-color, $amount: 30%);
      }
    }
  }
  .nav-icon {
    display: flex;
    position: fixed;
    bottom: 5vh;
    right: 5px;
    background-color: rgba(33, 33, 33, 0.7);
    border-radius: 50%;
    padding: 1rem;
    cursor: pointer;
    transition: background-color, 0.3s ease-in-out;

    &:hover {
      background-color: darken($color: rgba(33, 33, 33, 0.7), $amount: 10%);
    }
  }
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@import "./NavbarMobile";
