@import "../../variables";

.Contact {
  background-color: $secondary-color;
  margin-top: -0.1rem;
  padding: $top-and-bottom-section-padding;
  .form-submit-message {
    font-size: 0.9rem;
    font-weight: 800;
    color: rgb(19, 80, 19);
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  h4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid darken($secondary-color, 10%);
    width: 90%;
    margin-bottom: 8px;
    padding: 5px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
    sup {
      font-size: 0.5rem;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 5px;
    }
    label {
      align-self: flex-start;
      width: 100%;
    }
    input {
      padding: 0.2rem;
      margin: 5px 0;
    }
    input,
    textarea {
      border: 1px solid darken($secondary-color, 10%);
      border-radius: 5px;
      padding-left: 10px;
      width: 100%;

      &:focus {
        border: 2px solid darken($secondary-color, 20%);
        outline: none;
      }
    }
    button {
      color: $dark-text-color;
      padding: 0.8rem 1rem;
      margin: 5px 0;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      position: relative;
      height: 100%;
      background-color: darken($color: $secondary-color, $amount: 10%);
      transition: all 0.3s ease-in-out;

      &:hover,
      &:focus {
        color: #efefef;
        background-color: darken($color: $secondary-color, $amount: 20%);
      }
    }
  }
}
