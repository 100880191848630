@import "./variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  max-height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  font-size: 1.3rem;
  background-color: $primary-bg-color;
  color: $dark-text-color;
}

#root {
  position: relative;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Merriweather", serif;
  font-weight: 600;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3.5rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-size: 2.5rem;
}

h5 {
  font-size: 2rem;
}

h6 {
  font-size: 1.5rem;
}

p,
a,
strong,
em {
  font-family: "Noto Sans Wancho", sans-serif;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: $light-text-color;
}

.container {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards {
  width: 90%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .card {
    margin: 1rem;
    text-align: center;
    padding: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 50%;
      width: 100%;
      height: 0;
      z-index: -1;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
      color: $light-text-color;
      &::before {
        bottom: 0;
        height: 100%;
        background-color: $secondary-color;
      }
    }

    h6 {
      margin: 0.5rem 0;
    }
  }
}

@media screen and (min-width: 2560px) {
  body {
    font-size: 2rem;
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    font-size: 5.5rem;
  }

  h3 {
    font-size: 5rem;
  }

  h4 {
    font-size: 4.5rem;
  }

  h5 {
    font-size: 4rem;
  }

  h6 {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 0.7rem;
  }
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.9rem;
  }
}
