@media screen and (max-width: 600px) {
  .CoreValues {
    .cards {
      flex-direction: column;
      .card {
        min-height: 10vh;
      }
    }
  }
}
