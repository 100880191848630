@import "../../variables";

.Footer {
  background-color: $secondary-color;
  padding-bottom: 1rem;
  .container {
    flex-direction: row;
  }

  .icon {
    color: $dark-text-color;
  }
}
